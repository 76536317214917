import React from 'react';
import styled from 'styled-components';
import profileImage from '../../assets/profile.jpg';

const HeroSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 6rem 1.5rem 2rem;
  background-color: #0a192f;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding: 8rem 1.5rem 3rem;
  }
`;

const HeroContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    gap: 3rem;
    text-align: center;
  }
`;

const TextContent = styled.div`
  flex: 1;
  max-width: 600px;
  
  @media (max-width: 992px) {
    max-width: 100%;
  }
`;

const Greeting = styled.h3`
  color: #64ffda;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: 400;
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Name = styled.h1`
  color: #ccd6f6;
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 700;
  
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Title = styled.h2`
  color: #8892b0;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
`;

const Description = styled.p`
  color: #8892b0;
  font-size: 1.1rem;
  max-width: 600px;
  line-height: 1.6;
  margin-bottom: 2rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
`;

const CTAButton = styled.a`
  display: inline-block;
  background-color: transparent;
  color: #64ffda;
  border: 1px solid #64ffda;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: rgba(100, 255, 218, 0.1);
    transform: translateY(-3px);
  }
  
  @media (max-width: 768px) {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  
  @media (max-width: 992px) {
    max-width: 300px;
  }
`;

const ProfileImageWrapper = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #64ffda;
    border-radius: 10px;
    z-index: 1;
    transform: translate(10px, 10px);
    transition: transform 0.3s ease;
  }
  
  &:hover::after {
    transform: translate(5px, 5px);
  }
  
  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
    
    &::after {
      transform: translate(8px, 8px);
    }
    
    &:hover::after {
      transform: translate(4px, 4px);
    }
  }
  
  @media (max-width: 480px) {
    width: 200px;
    height: 200px;
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(20%);
  transition: filter 0.3s ease;
  
  &:hover {
    filter: grayscale(0%);
  }
`;

export default function Hero() {
  return (
    <HeroSection id="home">
      <HeroContent>
        <TextContent>
          <Greeting>Hi, my name is</Greeting>
          <Name>Dipak Paswan.</Name>
          <Title>I build things for the web & mobile.</Title>
          <Description>
            I'm a frontend developer specializing in building exceptional digital experiences. 
            Currently, I'm focused on building responsive web applications and mobile apps 
            using React, React Native, and WordPress.
          </Description>
          <CTAButton href="#contact">Get In Touch</CTAButton>
        </TextContent>
        
        <ImageContainer>
          <ProfileImageWrapper>
            <ProfileImage src={profileImage} alt="Dipak Paswan" />
          </ProfileImageWrapper>
        </ImageContainer>
      </HeroContent>
    </HeroSection>
  );
} 