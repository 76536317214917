import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header<{ scrolled: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: ${props => props.scrolled ? '0.8rem 0' : '1.5rem 0'};
  background-color: ${props => props.scrolled ? 'rgba(10, 25, 47, 0.95)' : 'transparent'};
  backdrop-filter: ${props => props.scrolled ? 'blur(10px)' : 'none'};
  transition: all 0.3s ease-in-out;
  z-index: 100;
  box-shadow: ${props => props.scrolled ? '0 5px 15px rgba(0, 0, 0, 0.1)' : 'none'};
`;

const NavContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;
`;

const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: #64ffda;
  cursor: pointer;
  
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
  
  span {
    color: #ccd6f6;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 2rem;
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled.a`
  color: #ccd6f6;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
  
  &:hover {
    color: #64ffda;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: #64ffda;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 300;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const MobileMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(10, 25, 47, 0.98);
  padding: 2rem;
  transform: translateX(${props => props.isOpen ? '0' : '100%'});
  transition: transform 0.3s ease-in-out;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (min-width: 769px) {
    display: none;
  }
`;

const MobileNavLinks = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
`;

const MobileNavLink = styled.a`
  color: #ccd6f6;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #64ffda;
    transition: width 0.3s ease;
  }
  
  &:hover {
    color: #64ffda;
    transform: translateY(-2px);
    
    &::after {
      width: 100%;
    }
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: none;
  border: none;
  color: #64ffda;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  z-index: 300;
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transition: all 0.3s ease-in-out;
  z-index: 150;
`;

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    document.body.style.overflow = !mobileMenuOpen ? 'hidden' : 'auto';
  };
  
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    document.body.style.overflow = 'auto';
  };
  
  return (
    <HeaderContainer scrolled={scrolled}>
      <NavContainer>
        <Logo>
          Dipak<span>Paswan</span>
        </Logo>
        
        <NavLinks>
          <NavLink href="#home">Home</NavLink>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#skills">Skills</NavLink>
          <NavLink href="#projects">Projects</NavLink>
          <NavLink href="#contact">Contact</NavLink>
        </NavLinks>
        
        <MobileMenuButton onClick={toggleMobileMenu}>
          <i className="fas fa-bars"></i>
        </MobileMenuButton>
      </NavContainer>
      
      <Overlay isOpen={mobileMenuOpen} onClick={closeMobileMenu} />
      
      <MobileMenu isOpen={mobileMenuOpen}>
        <CloseButton onClick={closeMobileMenu}>
          <i className="fas fa-times"></i>
        </CloseButton>
        <MobileNavLinks>
          <MobileNavLink href="#home" onClick={closeMobileMenu}>Home</MobileNavLink>
          <MobileNavLink href="#about" onClick={closeMobileMenu}>About</MobileNavLink>
          <MobileNavLink href="#skills" onClick={closeMobileMenu}>Skills</MobileNavLink>
          <MobileNavLink href="#projects" onClick={closeMobileMenu}>Projects</MobileNavLink>
          <MobileNavLink href="#contact" onClick={closeMobileMenu}>Contact</MobileNavLink>
        </MobileNavLinks>
      </MobileMenu>
    </HeaderContainer>
  );
} 