import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
  width: 100%;
  background-color: #111;
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
`;

const LogoGrid = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
`;

const LogoWrapper = styled.div`
  text-align: center;
`;

const Logo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const LogoTitle = styled.h3`
  color: #ccd6f6;
  margin-top: 1rem;
  font-size: 1.2rem;
`;

export default function LogoScene() {
  return (
    <LogoContainer>
      <LogoGrid>
        <LogoWrapper>
          <Logo
            src="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg"
            alt="React Logo"
          />
          <LogoTitle>React</LogoTitle>
        </LogoWrapper>
        
        <LogoWrapper>
          <Logo
            src="https://raw.githubusercontent.com/kristerkari/react-native-svg-transformer/master/images/react-native-logo.png"
            alt="React Native Logo"
          />
          <LogoTitle>React Native</LogoTitle>
        </LogoWrapper>
        
        <LogoWrapper>
          <Logo
            src="https://upload.wikimedia.org/wikipedia/commons/9/98/WordPress_blue_logo.svg"
            alt="WordPress Logo"
          />
          <LogoTitle>WordPress</LogoTitle>
        </LogoWrapper>
      </LogoGrid>
    </LogoContainer>
  );
} 