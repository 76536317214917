import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui, sans-serif;
    background-color: #0a192f;
    color: #ccd6f6;
    line-height: 1.3;
    overflow-x: hidden;
  }
  
  ::selection {
    background-color: #64ffda;
    color: #0a192f;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  ul {
    list-style: none;
  }
  
  button {
    cursor: pointer;
  }
  
  section {
    margin: 0 auto;
  }
  
  /* For WebKit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #0a192f;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #64ffda;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #4db6ac;
  }
`;

export default GlobalStyles; 