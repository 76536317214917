import React from 'react';
import styled from 'styled-components';

const ContactSection = styled.section`
  padding: 100px 0;
  background-color: #0a192f;
  
  @media (max-width: 768px) {
    padding: 60px 0;
  }
`;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 0 1.5rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: #ccd6f6;
  font-size: 2rem;
  margin-bottom: 1rem;
  display: inline-block;
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const SectionSubtitle = styled.h3`
  color: #64ffda;
  font-size: 1rem;
  margin-bottom: 2rem;
  font-weight: 400;
`;

const ContactText = styled.p`
  color: #8892b0;
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 3rem;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

const ContactButton = styled.a`
  display: inline-block;
  background-color: transparent;
  color: #64ffda;
  border: 1px solid #64ffda;
  border-radius: 4px;
  padding: 1rem 2rem;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: rgba(100, 255, 218, 0.1);
    transform: translateY(-3px);
  }
  
  @media (max-width: 768px) {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 4rem;
  
  @media (max-width: 768px) {
    gap: 1.5rem;
    margin-top: 3rem;
  }
`;

const SocialLink = styled.a`
  color: #ccd6f6;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  padding: 0.5rem;
  
  &:hover {
    color: #64ffda;
    transform: translateY(-3px);
  }
  
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

export default function Contact() {
  return (
    <ContactSection id="contact">
      <Container>
        <SectionSubtitle>What's Next?</SectionSubtitle>
        <SectionTitle>Get In Touch</SectionTitle>
        
        <ContactText>
          I'm currently looking for new opportunities. Whether you have a question or just want to say hi,
          I'll do my best to get back to you!
        </ContactText>
        
        <ContactButton href="mailto:dipak.paswan@example.com">
          Say Hello
        </ContactButton>
        
        <SocialLinks>
          <SocialLink href="#" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
            <i className="fab fa-github"></i>
          </SocialLink>
          <SocialLink href="#" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <i className="fab fa-linkedin-in"></i>
          </SocialLink>
          <SocialLink href="#" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <i className="fab fa-twitter"></i>
          </SocialLink>
          <SocialLink href="#" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <i className="fab fa-instagram"></i>
          </SocialLink>
        </SocialLinks>
      </Container>
    </ContactSection>
  );
} 