import React from 'react';
import styled from 'styled-components';
import LogoScene from './LogoScene';

const SkillsSection = styled.section`
  padding: 100px 0;
  background-color: #0a192f;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const SectionTitle = styled.h2`
  color: #ccd6f6;
  font-size: 2rem;
  margin-bottom: 3rem;
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 70px;
    height: 4px;
    background-color: #64ffda;
  }
`;

const SkillsIntro = styled.p`
  color: #8892b0;
  font-size: 1.1rem;
  line-height: 1.7;
  margin-bottom: 2rem;
  max-width: 800px;
`;

const SkillsContainer = styled.div`
  margin-top: 3rem;
`;

const SkillCategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const SkillCategory = styled.div`
  background-color: #112240;
  border-radius: 5px;
  padding: 2rem;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const CategoryTitle = styled.h3`
  color: #ccd6f6;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: #64ffda;
  }
`;

const SkillsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SkillItem = styled.li`
  color: #8892b0;
  font-size: 1rem;
  margin-bottom: 0.8rem;
  position: relative;
  padding-left: 20px;
  
  &::before {
    content: '▹';
    position: absolute;
    left: 0;
    color: #64ffda;
  }
`;

export default function Skills() {
  const skillCategories = [
    {
      title: 'Frontend Development',
      skills: ['React', 'JavaScript (ES6+)', 'TypeScript', 'HTML5', 'CSS3/SASS', 'Redux', 'Styled Components']
    },
    {
      title: 'Mobile Development',
      skills: ['React Native', 'Expo', 'Mobile UI/UX', 'App Performance', 'Native Modules']
    },
    {
      title: 'CMS & Other',
      skills: ['WordPress', 'PHP', 'RESTful APIs', 'Git/GitHub', 'Responsive Design', 'Web Performance']
    }
  ];
  
  return (
    <SkillsSection id="skills">
      <Container>
        <SectionTitle>Skills</SectionTitle>
        
        <SkillsIntro>
          I've worked with a variety of technologies in the web and mobile development world.
          From frontend to backend, I enjoy learning new technologies and applying them to create
          exceptional digital experiences.
        </SkillsIntro>
        
        <LogoScene />
        
        <SkillsContainer>
          <SkillCategoriesContainer>
            {skillCategories.map((category, index) => (
              <SkillCategory key={index}>
                <CategoryTitle>{category.title}</CategoryTitle>
                <SkillsList>
                  {category.skills.map((skill, skillIndex) => (
                    <SkillItem key={skillIndex}>{skill}</SkillItem>
                  ))}
                </SkillsList>
              </SkillCategory>
            ))}
          </SkillCategoriesContainer>
        </SkillsContainer>
      </Container>
    </SkillsSection>
  );
} 