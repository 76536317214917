import React from 'react';
import styled from 'styled-components';

const ProjectsSection = styled.section`
  padding: 100px 0;
  background-color: #0a192f;
  
  @media (max-width: 768px) {
    padding: 60px 0;
  }
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 1.5rem;
`;

const SectionTitle = styled.h2`
  color: #ccd6f6;
  font-size: 2rem;
  margin-bottom: 3rem;
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 70px;
    height: 4px;
    background-color: #64ffda;
  }
  
  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
`;

const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  
  @media (max-width: 768px) {
    gap: 60px;
  }
`;

const ProjectCard = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #112240;
    padding: 1.5rem;
    border-radius: 4px;
  }
`;

const ProjectContent = styled.div<{ align: 'left' | 'right' }>`
  grid-column: ${props => props.align === 'left' ? '1 / 8' : '6 / -1'};
  grid-row: 1;
  text-align: ${props => props.align};
  z-index: 2;
  
  @media (max-width: 768px) {
    grid-column: 1 / -1;
    text-align: left;
    order: 2;
  }
`;

const ProjectImageContainer = styled.div<{ align: 'left' | 'right' }>`
  grid-column: ${props => props.align === 'right' ? '1 / 8' : '6 / -1'};
  grid-row: 1;
  position: relative;
  z-index: 1;
  
  @media (max-width: 768px) {
    grid-column: 1 / -1;
    width: 100%;
    order: 1;
  }
`;

const ProjectImage = styled.div`
  width: 100%;
  height: 300px;
  background-color: #64ffda;
  border-radius: 4px;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  
  &:hover {
    opacity: 0.7;
  }
  
  @media (max-width: 768px) {
    height: 200px;
  }
`;

const ProjectTitle = styled.h3`
  color: #ccd6f6;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const ProjectDescription = styled.div`
  background-color: #112240;
  padding: 25px;
  border-radius: 4px;
  color: #8892b0;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  
  p {
    margin: 0;
  }
  
  @media (max-width: 768px) {
    background-color: transparent;
    padding: 0;
  }
`;

const TechList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  
  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const TechItem = styled.li`
  color: #8892b0;
  font-size: 0.9rem;
  font-family: monospace;
  
  &:not(:last-child)::after {
    content: '•';
    margin: 0 10px;
    color: #64ffda;
  }
`;

const ProjectLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  
  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

const ProjectLink = styled.a`
  color: #ccd6f6;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  padding: 0.5rem;
  
  &:hover {
    color: #64ffda;
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

export default function Projects() {
  const projects = [
    {
      title: 'E-commerce Website',
      description: 'A fully responsive e-commerce website built with React and Redux. Features include product filtering, cart functionality, user authentication, and payment integration.',
      technologies: ['React', 'Redux', 'Node.js', 'Express', 'MongoDB'],
      github: '#',
      live: '#',
      align: 'right' as const
    },
    {
      title: 'Task Management App',
      description: 'A mobile application for task management built with React Native. Users can create, edit, and delete tasks, set priorities, and receive notifications for upcoming deadlines.',
      technologies: ['React Native', 'Expo', 'Firebase', 'Redux'],
      github: '#',
      live: '#',
      align: 'left' as const
    },
    {
      title: 'Corporate Website',
      description: 'A custom WordPress theme for a corporate client. The website includes a blog, portfolio, team section, and contact form with custom post types and fields.',
      technologies: ['WordPress', 'PHP', 'JavaScript', 'SCSS'],
      github: '#',
      live: '#',
      align: 'right' as const
    }
  ];
  
  return (
    <ProjectsSection id="projects">
      <Container>
        <SectionTitle>Projects</SectionTitle>
        
        <ProjectsContainer>
          {projects.map((project, index) => (
            <ProjectCard key={index}>
              <ProjectContent align={project.align}>
                <ProjectTitle>{project.title}</ProjectTitle>
                <ProjectDescription>
                  <p>{project.description}</p>
                </ProjectDescription>
                <TechList>
                  {project.technologies.map((tech, techIndex) => (
                    <TechItem key={techIndex}>{tech}</TechItem>
                  ))}
                </TechList>
                <ProjectLinks>
                  <ProjectLink href={project.github} target="_blank" rel="noopener noreferrer" aria-label="GitHub Repository">
                    <i className="fab fa-github"></i>
                  </ProjectLink>
                  <ProjectLink href={project.live} target="_blank" rel="noopener noreferrer" aria-label="Live Demo">
                    <i className="fas fa-external-link-alt"></i>
                  </ProjectLink>
                </ProjectLinks>
              </ProjectContent>
              
              <ProjectImageContainer align={project.align}>
                <ProjectImage />
              </ProjectImageContainer>
            </ProjectCard>
          ))}
        </ProjectsContainer>
      </Container>
    </ProjectsSection>
  );
} 