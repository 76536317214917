import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  padding: 100px 0;
  background-color: #0a192f;
`;

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const SectionTitle = styled.h2`
  color: #ccd6f6;
  font-size: 2rem;
  margin-bottom: 3rem;
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 70px;
    height: 4px;
    background-color: #64ffda;
  }
`;

const AboutContent = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const AboutText = styled.div`
  color: #8892b0;
  
  p {
    margin-bottom: 1rem;
    line-height: 1.7;
    font-size: 1.1rem;
  }
`;

const SkillsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
`;

const SkillItem = styled.li`
  color: #8892b0;
  font-size: 1rem;
  position: relative;
  padding-left: 20px;
  
  &::before {
    content: '▹';
    position: absolute;
    left: 0;
    color: #64ffda;
  }
`;

export default function About() {
  const skills = [
    'JavaScript (ES6+)',
    'TypeScript',
    'React',
    'React Native',
    'WordPress',
    'HTML & CSS',
    'Node.js',
    'Redux',
    'Styled Components',
    'Git'
  ];
  
  return (
    <AboutSection id="about">
      <Container>
        <SectionTitle>About Me</SectionTitle>
        
        <AboutContent>
          <AboutText>
            <p>
              Hello! I'm Dipak, a frontend developer with a passion for creating beautiful and functional web and mobile applications. 
              I enjoy turning complex problems into simple, elegant solutions.
            </p>
            <p>
              My journey in web development started back in college when I was fascinated by how websites work. 
              Since then, I've been continuously learning and improving my skills to stay up-to-date with the latest technologies.
            </p>
            <p>
              I specialize in building responsive web applications using React and mobile apps with React Native. 
              I also have experience with WordPress development for content management systems.
            </p>
            <p>
              Here are a few technologies I've been working with recently:
            </p>
            
            <SkillsList>
              {skills.map((skill, index) => (
                <SkillItem key={index}>{skill}</SkillItem>
              ))}
            </SkillsList>
          </AboutText>
        </AboutContent>
      </Container>
    </AboutSection>
  );
} 